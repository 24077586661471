import React, { Component } from "react";
import NoData from "../../components/common/NoData";
import Loading from "../../components/common/Loading";
import Header from "../layout/Header";
import image3 from "../../images/Libraryimages/img3.JPG";
import image4 from "../../images/Libraryimages/img4.jpeg";
import dvcimg from "../../images/DVClibrary.jpg";
import pdf from "../../assets/listofjournals.pdf";
import firestore from "../../Config/Config";
import { doc, getDoc } from "firebase/firestore";

export class Library extends Component {
  state = { Datas: [] };

  //Load Data Here
  async componentDidMount() {
    //For Teaching
    const docRef = doc(firestore, "Library", "LibraryStaff");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      var Data = [];
      Object.entries(docSnap.data()).forEach(([key, value]) => {
        Data.push(
          Object.assign(
            {
              Id: key,
              Priority: value.Priority != undefined ? value.Priority : "999",
            },
            value
          )
        );
      });
      console.log(
        Data.sort(function (a, b) {
          return a.Priority - b.Priority;
        })
      );
      Data.length === 0
        ? this.setState({ Datas: "NO_999" })
        : this.setState({ Datas: Data });
    } else {
      this.setState({
        Datas: "NO_999",
      });
    }
  }

  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row library_banner">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading  card_box">
                    <h4 className="title" style={{ color: "white" }}>
                      VSDCH Library
                    </h4>
                    <div className="ttm-color-home">
                      <span>
                        <a
                          className="ttm-color-home"
                          title="Homepage"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          <i className="ti ti-home" />
                          &nbsp;&nbsp;Home
                        </a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>library</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="ttm-row aboutus-section-style3 clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                <div className="position-relative">
                  <div className="ttm_single_image-wrapper text-sm-center mr-35 res-991-m-0">
                    <img
                      className="img-fluid"
                      src={image4}
                      alt="single-img-nine"
                      style={{ height: "500px" }}
                    />
                  </div>
                  <br />
                  <div
                    className="member-info-box border"
                    style={{ padding: "10px", textAlign: "left" }}
                  >
                    <div className="timing-box">
                      <h4> Dr. Shashikala</h4>
                    </div>
                    <ul className="info-list">
                      <li>
                        <span>Qualification : M.LI.Sc., M.Phil., Ph.D</span>
                      </li>
                      <li>
                        <i className="ti ti-star" />
                        <span>
                          Research interest: Utilization of electronic
                          information sources and services in Medical College
                          Libraries in Bangalore - A Case Study
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <br />
                <div className="featured-content">
                  <div className="featured-title">
                    <h5>Working Hours</h5>
                  </div>
                  <div className="featured-desc">
                    <p>Monday to Saturday- 8:30 AM to 9 PM</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="res-991-mt-30">
                  <div className="section-title with-desc clearfix">
                    <div className="title-header">
                      <h2 className="title">Vision</h2>
                    </div>
                    <div className="title-desc">
                      <p>
                        Providing better visibility about the information
                        resources and service of our library and educate users
                        with formal and informal (on-site guidance) way
                      </p>
                    </div>
                  </div>
                  <div className="separator">
                    <div className="sep-line mt-4 mb-3" />
                  </div>
                  <div className="section-title with-desc clearfix">
                    <div className="title-header">
                      <h2 className="title">Mision</h2>
                    </div>
                    <div className="title-desc">
                      <p>
                        Providing access to maximum quality information
                        resources and users satisfaction is the main concern
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="section-title with-desc clearfix">
                        <div className="title-header">
                          <h2 className="title">
                            Digital Valuation <span>Centre</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="res-991-mt-30">
                        <div className="section-title with-desc clearfix">
                          <div className="title-desc">
                            <p>
                              DVC was established in Central Library of V S
                              Dental College and Hospital in 2020 with an
                              infrastructure comprising of 28 computers and ACT
                              Fibernet internet connection. It is tagged as one
                              of the centres by RGUHS for theory paper
                              evaluation.
                            </p>
                            <p>
                              It extends support to the sister institutes such
                              as Nursing and Physiotherapy institutions to make
                              use of the DVC for their University examinations
                            </p>
                            <a
                              href={pdf}
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                              className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                            >
                              List of Journals
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="position-relative">
                        <div className="ttm_single_image-wrapper text-sm-center mr-35 res-991-m-0">
                          <img
                            className="img-fluid"
                            src={dvcimg}
                            alt="single-img-nine"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="ttm-row zero-padding-section res-991-p-0 mt_80 res-991-mt-0 clearfix">
          <div className="container-xl">
            <div className="row no-gutters">
              <div className="col-lg-6">
                <div className="col-bg-img-one ttm-bg ttm-col-bgimage-yes ttm-left-span res-991-h-auto">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                    <div className="ttm-bg-layer-inner" />
                  </div>
                </div>
                <img
                  src={image3}
                  className="img-fluid ttm-equal-height-image"
                  alt="col-bgimage-1"
                />
              </div>
              <div className="col-lg-6 z-9">
                <div className="ttm-bgcolor-skincolor ttm-textcolor-white position-relative ttm-right-span">
                  <div className="spacing-1">
                    <div className="section-title with-desc clearfix">
                      <div className="title-header">
                        <h2 className="title">
                          Objectives
                          <span className="ttm-textcolor-white"></span>
                        </h2>
                      </div>
                    </div>
                    <p>
                      To select, acquire, preserve and disseminate relevant and
                      latest information resources.
                    </p>
                    <br />
                    <p>
                      To develop state of the art IT infrastructure to provide
                      user-friendly access to all the information in the
                      library.
                    </p>
                    <br />
                    <p>
                      To develop integrated services to helps learning, research
                      and practice.
                    </p>
                    <br />
                    <p>
                      To develop integrated services to helps learning, research
                      and practice.
                    </p>
                    <br />
                    <p>
                      To implement latest technology, on par with the users
                      expectation. Periodical training and lectures from the
                      eminent library scientist and experts about information
                      product and technology, as a part of information and
                      technology literacy program in the library.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="ttm-row aboutus-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Well Equipped State of the Art <span>Library</span>
                    </h2>
                  </div>
                </div>
                <div className="row no-gutters mt-4 mb-4">
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Books – 7742
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Titles – 2945
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Journals – 48 (International-33,National - 15)
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Online e-journals (HELINET) – 48
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Bound volumes – 2417
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          CD-ROM – 514
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Theses library dissertation – 514
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Internet – 12+19
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Photocopying machine – 01
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Printer – 02
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Seating capacity – 325
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Total library area – 9000 sq. ft
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Kannada Novels and Textbooks - 424
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">Internet </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Provision for Photocopies
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Open access system
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          New arrivals display
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Separate departmental library for UG/PG 17. SC/ST book
                          bank
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  <div
                    className="ttm_single_image-wrapper text-sm-center"
                    style={{ marginTop: "130px" }}
                  >
                    <img
                      className="img-fluid"
                      src={image3}
                      title="single-img-seven"
                      alt="single-img-seven"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/**Library staff section */}
        <div className="container-xl">
          <div className="section-title with-desc clearfix">
            <div className="title-header">
              <h2 className="title">
                Library<span> Staff</span>
              </h2>
            </div>
          </div>
          <div className="row">
            {this.state.Datas.length === 0 ? (
              <Loading />
            ) : this.state.Datas === "NO_999" ? (
              <NoData />
            ) : (
              this.state.Datas.map((data, i) => (
                <div className="col-md-6 col-lg-4 col-sm-6" Key={i}>
                  <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                    <div className="featured-thumbnail">
                      <img className="img-fluid" src={data.Image} alt="image" />
                    </div>
                    <div className="featured-content box-shadow">
                      <div className="featured-title">
                        <h5>{data.Name}</h5>
                      </div>
                      <p className="category">{data.Designation}</p>
                      <p className="category">Experience: {data.Experience}</p>
                      <p className="category">
                        Qualification: {data.Qualification}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Library;
